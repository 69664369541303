.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.modal-title {
  font-size: 16px;
}

.App-link {
  color: #61dafb;
}

#countdowntimer {
  font-size: 16px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

canvas {
  height: 200px !important;
}

.nh canvas {
  height: 224px !important;
}

.info-box .info-box-icon {
  font-size: 20px !important;
}

.info-box-number {
  font-size: 22px !important;
}

.daily {
  line-height: 14px;
}

.daily span {
  font-size: 12px !important;
}

.text-dark {
  padding-left: 10px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

.err-msg {
  color: red;
  font-size: 12px;
  font-weight: bold;
}

.dynamicForm td {
  line-height: 12px;
}

.react-datepicker-wrapper {
  display: block !important;
}

.days {
  padding: 0;
  margin: 0;
  list-style: none;
}

.days li {
  margin-right: 10px;
  margin-bottom: 10px;
  float: left;
}

.days .today-bg a {
  background: rgb(230, 122, 21) !important;
  cursor: pointer !important;
  color: #fff !important;
}

.days li a {
  background-color: #f1f1f1;
  border-radius: 5px;
  display: block;
  min-width: 30px;
  text-align: center;
  height: 30px;
  padding-top: 5px;
  color: #000;
  cursor: default;
  border-radius: 30px;

}

/* .days li a:focus {
  background-color: #e0a800 !important; border: 0;
} */
.days {
  border: 0;
  text-decoration: none;
}

.days .active a {
  background-color: #3d6db5 !important;
  border: 0;
  color: #fff !important;
}

.days a {
  background: #d2d0ee !important;
  color: #000 !important;
  cursor: pointer !important;
}

.spacer {
  margin-top: 30px;
}

.rdt_TableHeader {
  display: none !important;
}

.controls {
  margin: 0 0 20px 0;
  padding: 0 0 10px 0;
  list-style: none;
  float: right;
  border-bottom: 1px solid #eee;
  width: 100%;
  text-align: right;
}

.controls li {
  margin-left: 10px;
  display: inline-block;
}

.weightList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.labelText {
  font-size: 14px;
  text-align: right;
  margin-top: 5px;
}

.refresh {
  cursor: pointer;
  font-size: 12px;
}

.refresh:hover {
  text-decoration: underline;
}

.custom-alert {
  padding: 5px 10px;
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: bold;
}

.alert .close {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #fff !important;
  font-size: 14px;
}

.login-box,
.register-box {
  margin: auto;
}

.font12 {
  font-size: 14px;
}

.mt10 {
  margin-top: 30px;
}

.styles_Editext__button__6H8n_ {
  padding: 2px !important;
}

.styles_Editext__input__1534X {
  padding: 2px 10px !important;
}

.dailyRow {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 5px 0 10px 0;
}

.borderless td,
.borderless th {
  border: none;
}

.blueColor {
  color: #3d6db5;
  font-weight: bold;
  text-transform: uppercase;
}

.summary-table th {
  background: #eee;
  color: #000;
}

/* .summary-table td { background: #eee; } */
#summary .inner h3 {
  font-size: 24px;
}

.tabs {
  width: 100%;
}

.tab-content {
  padding-top: 15px;
}

.nav-item {
  cursor: pointer;
}

.greenbg {
  background-color: #00933b;
  color: #fff;
  padding: 3px 5px;
  border-radius: 5px;
}

.redbg {
  color: #f33900;
  padding: 3px 5px;
  border-radius: 5px;
  font-style: italic;
}

.view-btn {
  color: #3d6db5;
  cursor: pointer;
}

.tab-head1 {
  background-color: #777;
  color: #fff;
}

.tab-head2 {
  background-color: #f1f1f1;
  color: #000;
}

.phases th,
td {
  vertical-align: middle;
  padding: 5px;
  font-size: 14px;
}

.text-size {
  font-size: 14px !important;
}

.padding-align {
  padding: 0 15px;
}

.batchStatus {
  font-size: 14px;
  color: #c82333;
}

.expensesList {
  padding: 0;
  margin: 0;
}

.expensesList li {
  list-style: none;
}

.spanFont {
  font-weight: bold;
  font-size: 12px;
}

.totalBox {
  color: #3d6db5;
  font-weight: bold;
}

.totalBox .number {
  color: #282c34;
}

.displaynone {
  display: none;
}

.bluebg {
  background: #3d6db5;
  color: #fff;
  padding: 3px 5px;
  border-radius: 5px;
}

.workers th,
.workers td {
  padding: 5px;
}

.select2-container .select2-selection--single {
  height: 38px !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 6px;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 0;
}

.info-box2 {
  width: 30px;
}

.icon-holder {

  /* background: #555; border-radius: 35px; */
  float: left;
  margin-bottom: 20px;
  width: 40px;
}

.text-holder {
  float: left;
  padding-left: 10px;
  margin-top: 10px;
}

.icon-holder i {
  color: #fff;
  width: 100%;
}

.icon-holder .f-18 {
  font-size: 18px;
}

.color-red,
.red {
  color: #c82333;
}

.green {
  color: #00933b
}

.yellow {
  color: gold;
}

.red {
  color: #c82333;
}

.loadingPage {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10000;
}

.gray {
  color: #666;
}

.signal {
  font-size: 14px;
  display: inline-block;
  margin-top: 5px;
  font-weight: bold;
}

.buttonsUL {
  margin: 0;
  padding: 0;
}

.buttonsUL li {
  list-style: none;
  float: left;
  margin-right: 10px;
}

.myappcontent {
  padding: 15px;
  min-height: 87vh;
}

/* .notifications-dropdown {
  max-width: 100%;
} */
.widget-sm {
  background: #fff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 5px 5px 10px #ccc;
  min-height: 150;
}

h6 {
  color: '#0085FF !important'
}

.bigNumber span {
  font-size: 40px;
  display: inline-block;
}

.bigNumber {
  text-align: right;
}

.viewDetails {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  color: #555;
  cursor: pointer;
}

.viewDetails :hover {
  text-decoration: underline;
  color: #0079FF !important;
}

.chartArea {
  margin-left: 0;
  margin-right: 0;
}

.clear {
  clear: both;
  font-size: 0;
}

.card-header i {
  color: #0079FF
}

.main-header {
  box-shadow: 1px 1px 5px #ccc;
  padding: 0;
}

.echarts-for-react {
  top: 0
}

.main-footer {
  text-align: center;
  padding: 15px;
  background: '#EEE';
  box-shadow: 5px 5px 15px #eee;
}

.content {
  background: #f7f7f7;
}

.stepwizard-step p {
  margin-top: 10px;
}

.stepwizard-row {
  display: table-row;
}

.stepwizard {
  display: table;
  width: 50%;
  position: relative;
}

.stepwizard-step button[disabled] {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}

.stepwizard-row:before {
  top: 14px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 100%;
  height: 1px;
  background-color: #ccc;
  z-order: 0;
}

.stepwizard-step {
  display: table-cell;
  text-align: center;
  position: relative;
}

.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}

.spacer {
  margin-top: 20;
}

.text-success {
  color: #218838;
  font-weight: bold;
}

.text-danger {
  color: #c82333;
  font-weight: bold;
}

h3 {
  font-size: 18px !important;
  text-transform: uppercase;
}

h4 {
  font-size: 16px !important;
}

.entry {
  padding: 2px;
  background-color: #218838;
  cursor: pointer;
  color: #fff;
  text-align: center;
  display: inline-block;
  width: 100px;
  border-radius: 5px;
}

.smallFont {
  font-size: 11px;
  color: #555;
}

.alignDashboardLoader {
  margin-top: 0;
  padding-top: 0;
}

.dashbioardLoderContainer {
  position: relative;
}

.dashboardLoader {
  position: absolute;
  top: -30px;
  right: 140px;
}

.loggedas {
  font-weight: 400;
  margin-top: 8px;
  padding-left: 10px;
  padding-right: 10px;
}

.loggedasButton {
  background: #009ec3;
  color: #fff !important;
  text-align: center;
  padding: 5px 10px !important;
  border-radius: 5px;

  height: auto !important;
  margin-top: 4px;
}

.alertIcon i {
  font-size: 20px;
}

.dropdown-item {
  font-size: 14px;
}

.dropdown-item:hover {
  background: #3d6db5;
  color: #fff;
}

.nav-item a.active {
  color: #3d6db5 !important
}

.navBar .nav-link:hover {
  background: #3d6db5;
  color: #FFF !important
}

.navBar .nav-link {
  color: #000 !important;
  font-weight: 600;
  font-size: 13px;
  text-transform: capitalize;
}

.dayReadings {
  float: right;
  list-style: none;
  padding: 0 0 0 0;
  margin: 0;
}

.dayReadings li {
  display: inline-block;
  padding-left: 10px;
  font-size: 14px;
  padding-right: 10px;
  border-right: 1px solid #ccc;
  text-transform: uppercase;
  font-size: 12px;
}

.card-header i {
  color: #3d6db5;
}

.card-header h6 {
  font-weight: 700;
}

.text-bg {
  background: #333;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 10px;
}

.dayReadings li:last-child {
  padding-right: 0;
  border-right: none;
}

.orange-bg {
  background-color: #416db5;
}

.main-header {
  border-top: 5px solid #3d6db5;
  padding: 10px 0;
}

.padding15 {
  padding: 0 10px
}

.purpleText {
  color: #3d6db5;
  font-weight: bold;
}

.phaseDetails {
  text-align: right;
}

.widgets {
  margin-top: 15px;
  padding: 0 8px;
}

.card h6 {
  font-weight: 700 !important;
}

.smallNumber {
  font-size: 16px !important;
  font-weight: bold;
  color: #666;
}

.fanImanges li {
  list-style: none;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}

.fanImanges li img {
  width: 24px
}

.fanImanges {
  margin: 0;
  padding: 0;
}

.brand-text {
  color: #000;
  font-size: 20px;
  margin-top: 4px;
  display: inline-block;
}

.tabs .nav-link {
  font-size: 16px;
  border: 0;
}

.tabsArea {
  margin-top: 15px;
}

.tabs a.active {
  border-bottom: 2px solid #3d6db5 !important;
  font-weight: bold;
}

.imgHolder {
  text-align: center;
}

.highLevelreadings li {
  list-style: none;
  margin-bottom: 5px;

}

.highLevelreadings {
  padding: 0;
  margin: 0;
  padding-left: 60px;
}

.highLevelreadings li span {
  font-weight: 700;
}

.currentReading {
  font-size: 22px;
  font-weight: bold;
}

.batchops .card {
  min-height: 330px;
}

.rightWidgetsData {
  padding: 0 0 0 60px;
  margin: 15px 0 0 0;
}

.rightWidgetsData li {
  list-style: none;
  margin-bottom: 10px;
  font-weight: bold;
  color: #3d6db5;
}

.rightWidgetsData li span {
  color: #000;
}

.redcolor {
  color: red !important;
}

.moreBtn {
  width: 90%;
  display: none;
  margin: 50px auto 0 auto;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  border: 1px solid #3d6db5;
  color: #3d6db5;
  font-size: 14px;
  font-weight: 700;
  padding: 10px;
  border-radius: 5px;
}

.moreBtn:hover {
  background: #3d6db5;
  color: #fff;
}

.manageBtn {
  margin: 0;
  width: 200px;
}

.font12 {
  font-size: 10px;
}

.fans {
  list-style: none;
  margin: 25px 0 25px 0;
}

.fanicon {
  width: 80% !important;
}

.fans li {
  display: inline-block;
  margin-right: 10px;
}


@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

.rotating {
  -webkit-animation: rotating 1s linear infinite;
}

.lastUpdated {
  list-style: none;
  padding: 0;
  margin: 5px 0 0 0;
}

.lastUpdated li {
  display: block;
  float: left;
}

.lastUpdated li:first-child {
  margin-right: 3px;
}

.login-left {
  background-image: url('../public/dist/img/auth-side-bg.jpg');
  background-repeat: no-repeat;
  padding: 15px;
}

.login-page {
  height: auto;
}

.logoText {
  color: #fff;
  font-size: 30px;
  margin-top: 2px;
}

.logo-placeholder {
  margin: 60px auto 20px auto;
}

.logo-placeholder span {
  display: block;
  float: left;
}

.logo-placeholder span:first-child {
  margin-right: 10px;
}

.left-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  color: #fff;
}

.left-content .text {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}

.copy {
  text-align: center;
}

.login-placeholder {
  width: 50%;
  margin: 70px auto;
}

.login-page {
  background: #fff;
}

.btn-primary {
  background-color: #3d6db5;
  border-color: #3d6db5;
}

.btn-primary:hover {
  background-color: #3d6db5;
  border-color: #3d6db5;
}

.icheck-primary>input:first-child:checked+input[type=hidden]+label::before,
.icheck-primary>input:first-child:checked+label::before {
  background-color: #3d6db5;
  border-color: #3d6db5;
}

a {
  color: #3d6db5;
}

.signin {
  padding: 10px 0;
}

h1,
h2 {
  font-family: 'Inter', sans-serif !important;
}

.tablist li.nav-item a.active {
  background: none !important;
}

.outcomeText {
  font-size: 16px;
  font-weight: 700;
  color: #3d6db5;
}

.card-title {
  font-size: 14px !important;
}

.gJhVev,
.sc-evZas {
  font-weight: bold;
  text-transform: uppercase;
}

.update-btn {
  border-color: #e08e00;
  color: #e08e00;
}

.waterMeter-widget p {
  margin-bottom: 5px;
}

.waterMeter-widget h3 {
  font-weight: bold;
}

.bigText {
  font-size: 32px;
}

.green {
  color: #00933b;
}

.heading-bg {
  background: #3d6db5;
  text-transform: uppercase;
  padding: 5px 5px 5px 15px;
  color: #fff;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-top: 15px;
}

.heading-img img {
  width: 100%;
  margin: auto;
}

.heading-img {
  width: 80%;
  margin: 5px auto 0 auto;
  text-align: right;
}

.cardHeader .col-md-3 {
  margin-right: 0;
}

.fans {
  padding-left: 0;
}

.section2 {
  min-height: 385px !important;
}

.fans img {
  width: 100px;
  display: inline-block;
}

.fan-number {
  font-weight: bold;
  text-align: center;
}

.ul-align {
  padding-left: 15px;
  margin-left: 0;
}

.newBtn {
  min-width: 100px;
  margin-right: 10px;
}

.phaseDetails h6 {
  font-size: 12px;
}

#batchSummary .tablist .active {
  font-weight: 0 !important;
  color: #fff !important;
}

.cardHeight {
  min-height: 275px;
}

.faninfo_errMsg {
  text-align: center;
  padding: 63px 0;
}

.heading-bg .title {
  float: left;
}

.heading-bg .rightButton {
  display: block;
  float: right;
  text-transform: initial;
  color: #fff;
  padding: 2px 10px;
  background: #00933b;
  border-radius: 10px;

}

.clear {
  clear: both;
}

.fanLabel {
  font-weight: bold;
  margin-top: 10px;
  display: inline-block;
}

#dailyOperations h5 {
  font-weight: bold !important;
  font-size: 14px !important;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

#dailyOperations {
  padding-top: 10px;
}

.sexingStatus {
  margin-bottom: 20px;
}

.textHiglighter {
  display: inline-block;
  background-color: #218838;
  color: #fff;
  font-weight: bold;
  padding: 2px 5px;
  font-size: 12px;
  border-radius: 10px;
  margin-right: 10px;
}

.modalTitle {
  font-size: 16px;
  padding: 20px;
  border-bottom: 1px solid #ccc;
}

.dashboard h3 {
  font-size: 16px !important;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}

.iconHolder {
  width: 90%;
  height: 75px;
  padding: 10px 15px;
  background: #333;
  border-radius: 50px;
}

.widgetRow {
  min-height: 120px;
}

.widgetText {
  font-weight: bold;
  margin-bottom: 10px;
}

.iconHolder img {
  align-items: center;
}

.borderRight {
  border-right: 1px solid #ccc;
  padding-right: 10px;
}

.rbc-event-content {
  text-align: center !important;
  justify-self: center !important
}

.rbc-row-segment {
  /* height: 100px !important; */
}

.order-details {
  display: flex;
  flex-direction: column;
}

.detail-row {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.detail-key {
  font-weight: bold;
  width: 250px;
  padding: 1%;
  /* Adjust as needed for key width */
}

.detail-value {
  margin-left: 16px;
}

li.riskdetails {
  /* font-size: 20px; */
  padding: 5%;
  white-space: nowrap;
}

li.PoNumberList {
  /* font-size: 20px; */
  padding: 5%;
  white-space: nowrap;
  cursor: pointer;
}

li.PoNumberList:hover {
  background-color: #ccc;
}

.title.row {
  background-color: #f1f1f1;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.side-overlay {
  position: fixed;
  top: 0;
  right: -100%;
  height: 100%;
  background-color: white;
  transition: right 0.3s ease-in-out;
  z-index: 1050;
  overflow-y: auto;
}

.side-overlay.open {
  right: 0;
}

.overlay-content {}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 16px;
}


.popover {
  position: absolute;
  top: 20%;
  left: 32%;
  transform: translate(-50%, -50%);
  background-color: #f1f1f1;
  border: 1px solid black;
  padding: 10px;
  z-index: 9999;
  border-radius: 0px 10px 10px 10px;
  width: 16%;
}

.popover-content {
  /* Add styles for popover content */
}

.popoverlist {
  padding: 4%;
  white-space: nowrap;
  /* border-bottom: 1px solid #ccc; */
}

.cell-checkboxInput {
  display: flex;
  justify-content: center !important;
  align-items: center;
  margin-left: 45%;
}

.widgetRow {
  min-height: 120px;
}

.widgetText {
  font-weight: bold;
  margin-bottom: 10px;
}

.iconHolder img {
  align-items: center;
}

.borderRight {
  border-right: 1px solid #ccc;
  padding-right: 10px;
}

.blue-bg {
  background-color: #1d69e7;
}

.info-box-text {}

.title-right {
  text-align: right;
  background-color: #402d9f;
  padding: 5px 10px;
  color: #fff;
  border-radius: 40px;
}

#chart-container {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

#usmap canvas {
  height: 500px !important;
}

#lineChart canvas {
  height: 300px !important;
}

.statesLegends li {
  display: inline-block;
  margin-right: 15px;
  list-style: none;
}

.statesLegends {
  float: right;
  font-size: 12px;
}

.businessStates {
  width: 24px;
  height: 15px;
  border-radius: 5px;
  background-color: #fab32a;
  display: inline-block;
  margin-right: 2px;
}

.riskSates {
  width: 24px;
  height: 15px;
  border-radius: 5px;
  background-color: #c80606;
  display: inline-block;
  margin-right: 2px;
}

.nonBusinessStates {
  width: 24px;
  height: 15px;
  border-radius: 5px;
  background-color: #c9c9c9;
  display: inline-block;
  margin-right: 2px;
}

.idealSates {
  width: 24px;
  height: 15px;
  border-radius: 5px;
  background-color: #00983d;
  display: inline-block;
  margin-right: 2px;
}

.info-box-number {
  position: relative;
}

.info-box-number small {
  font-size: 13px;
}

.info-box-number {
  margin-top: 10px !important;
  display: inline-block;
}

.caret {
  position: absolute;
  bottom: -18px;
  right: 15px;
}

#logisticRisks h2,
#qaRisks h2,
#inventoryRisks h2 {
  font-size: 16px;
}

.textRight {
  text-align: right;
}

.textRight span {
  font-size: 22px;
  font-weight: 600;
}

.number {
  font-weight: 600;
  font-size: 16px;
}

.tableArea {
  padding-top: 10px;
}

.btnBack {
  background-color: #402d9f;
  color: #fff !important;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 40px;
  font-weight: bold;
}

.btnBack:hover {
  background-color: #402d9f4d;
  color: #000 !important;
}

.tableRowClick {
  cursor: pointer;
}

.tableRowClick:hover {
  background: #ffc0cb52 !important;
}

.tableHeading {
  background-color: #f1f1f1;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 2%;
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.sideNav {
  margin-top: 15px;
  list-style: none;
  padding-left: 0;
  max-height: 350px;
  overflow: auto;
  border-right: 1px solid #ccc;
}

.sideNav li {
  list-style: none;
  padding: 5px 10px;
  margin-bottom: 2px;
  text-align: right;
  cursor: pointer;
}

.sideNav li:hover {
  background: #e1e1e1;
}

.circle {
  width: 8px;
  height: 8px;
  background-color: #333;
  border-radius: 30px;
  display: inline-block;
}

.redIndicator {
  background-color: red;
}

.taskView h2 {
  border-top: 1px solid #ccc;
  font-size: 14px;
  margin-top: 15px;
  font-weight: bold;
  padding: 10px 0;
  text-transform: uppercase;
}

.tabs .nav-link {
  font-size: 14px;
}

.modal-header .btn-close {
  display: none;
}

.card-body {
  padding: 15px;
}

.extraLinks {
  list-style: none;
}

.extraLinks li {
  cursor: pointer;
}

.extraLinks li:hover {
  color: #3382f5;
}

.greenIndicator {
  background-color: #00983d;
}

.orangeIndicator {
  background-color: #fa8c3c;
}

.workItemActive {
  background-color: #e1e1e1;
}

.workHeader h2 {
  font-size: 14px;
  padding-top: 4px;
  margin-bottom: 0;
  text-transform: uppercase;
}

.workHeader {
  background-color: #f1f1f1;
  padding: 10px;
  border-left: 4px solid #3382f5;
}

.workHeader .status {
  float: right;
  text-align: right;
  font-size: 12px;
}

.workHeader span {
  padding: 2px 5px;
  text-align: center;
  border-radius: 5px;
  background: red;
  color: #fff;
  display: inline-block;
}

.textRight {
  text-align: right;
}

.summaryHeader {
  font-weight: bold;
}

.workSummary {
  padding-top: 15px;
  text-align: center;
}

.workSummary_heading {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}

.taskView h2 {
  border-top: 1px solid #ccc;
  font-size: 14px;
  margin-top: 15px;
  font-weight: bold;
  padding: 10px 0;
  text-transform: uppercase;
}

.tabs .nav-link {
  font-size: 14px;
}

.modal-header .btn-close {
  display: none;
}

.card-body {
  padding: 15px;
}

.extraLinks {
  list-style: none;
}

.extraLinks li {
  cursor: pointer;
}

.extraLinks li:hover {
  color: #3382f5;
}

body {
  font-size: 12px;
}

#sideOverlay h2 {
  font-size: 14px !important;
}

.sideOverlaySummary,
#sideOverlayMenu li a {
  font-size: 13px !important;
}

.textHighlighter {
  padding: 5px 10px;
  text-align: center;
  border-radius: 5px;
  background-color: #3382f5;
  color: #fff;
}

.productFilterItems label,
.productFilterItems select {
  display: inline-block;
}

.productFilter {
  float: right;
}

#sideOverlayMenu li a {
  color: #333;
}

#sideOverlayMenu li a:hover {
  background-color: #d2e2f8;
}

#sideOverlaytable .header {
  font-weight: 600;
  font-size: 14px;
}

#sideOverlaytable {
  font-size: 12px;
}

.textHighlighter {
  padding: 5px 10px;
  text-align: center;
  border-radius: 5px;
  background-color: #3382f5;
  color: #fff;
}

#addMore {
  margin-top: 26px;
}

.my-stepper .MuiStepIcon-root.MuiStepIcon-completed {
  color: tomato !important;
}

.duration {
  top: 20px;
  background: white;
  border: 1px solid #bebec1;
  padding: 5px;
  font-size: 12px;
  justify-content: center;
  align-items: center;

}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: #00933b !important;
}

.shipmentTracking h2 {
  font-size: 14px;
  font-weight: bold;
}

.accordion-button {
  font-size: 14px !important;
  /* text-transform: uppercase !important; */
}


.hidden-column {
  display: none;
}

.PoNumberListHighlighted {
  color: #3382f5;
  font-weight: bold;
}

.PoNumberList {
  color: black;
}

.noRisk::marker {
  color: #00933b;
  font-size: 20px;
}

.risk::marker {
  color: red;
  font-size: 20px;
}


.chips-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  justify-content: end;
}

.chip {
  background-color: #e0e0e0;
  color: #333;
  border-radius: 16px;
  padding: 4px 8px;
  margin: 4px;
  display: flex;
  align-items: center;
}

.chip:hover {
  background-color: #84b2c3;
  color: white;
}

.close-btn {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  margin-left: 4px;
}

.custom-button-container {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 10px;
  /* Adjust as needed */
  margin-right: 10px;
  /* Adjust as needed */
}

a.disabled-link {
  margin-left: 9% !important;
  color: gray !important;
  cursor: not-allowed !important;
}